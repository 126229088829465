<template>
  <v-dialog
    v-model="confirmRoundRankingConsolidationModal"
    width="800px"
    :retain-focus="false"
  >
    <v-card
      class="py-5 pb-5"
    >
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Tem certeza que deseja consolidar o ranking da <b>{{round.number}}° Rodada</b>? <br><br>
          Tenha certeza de: <br>
          <div  class="body-1">
            - Essa ação só pode ser realizada uma vez por rodada;<br>
            - Ter averiguado se todos os jogos da rodada foram encerrados;<br>
            - Ter calculados os pontos depois de todos os jogos terem terminado;<br>
            - Por garantia, você pode calcular os pontos mais uma vez e esperar alguns minutos antes de realizar essa ação.
          </div>
        </div>
      </v-card-title>

      <v-card-actions class="mt-5 mx-5">
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            :disabled="saving"
            @click="consolidateRanking()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Sim
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    round: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saving: false
    }
  },
  computed: {
    confirmRoundRankingConsolidationModal: {
      get () {
        return this.$store.state.confirmRoundRankingConsolidationModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'confirmRoundRankingConsolidationModal',
          value: val
        })
      }
    }
  },
  methods: {
    consolidateRanking () {
      this.saving = true
      const championshipId = this.$store.state.selectedChampionship
      const roundId = this.round._id
      this.$emit('started')

      this.$http.post('/ranking/consolidate-ranking', null, {
        params: {
          championship: championshipId,
          round: roundId
        }
      })
        .then(() => {
          this.saving = false
          this.confirmRoundRankingConsolidationModal = false
          this.$emit('success')
          this.$toast.success('O Ranking começou a ser consolidado. Espere alguns minutos.')
        })
        .catch((err) => {
          this.saving = false
          this.$toast.error(err.response.data.message)
        })
    }
  }
}
</script>
